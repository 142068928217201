import { DocumentType, QuestionType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import MessageBubble from '~/components/Message/Bubble';
import QuestionAction from '~/components/Question/Action';
import QuestionChildren from '~/components/Question/Children';

const Question: FC<{
  contactId: string;
  onConfirm?: () => void;
  userId: string;
  value: DocumentType<QuestionType>;
}> = function ({
  contactId,
  onConfirm,
  userId,
  value: { espaceId, id, kind, label, nbChildren, subLabel },
}) {
  return (
    <div>
      <MessageBubble tail text={label} type="recipient" />
      {subLabel && (
        <p className="pt-3 text-center text-[12px] text-light-800">
          {subLabel}
        </p>
      )}

      {0 === nbChildren && (
        <QuestionAction
          contactId={contactId}
          espaceId={espaceId}
          kind={kind}
          onConfirm={onConfirm}
          questionId={id}
          userId={userId}
        />
      )}
      {nbChildren !== undefined && nbChildren > 0 && (
        <QuestionChildren
          espaceId={espaceId}
          onConfirm={onConfirm}
          parentId={id}
          userId={userId}
        />
      )}

      {/* {answers && */}
      {/*  answers.map(answer => ( */}
      {/*    <MessageItem key={answer} label={answer} tail={tail} type="sender" /> */}
      {/*  ))} */}
    </div>
  );
};

export default Question;
