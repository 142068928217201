import { AnswerType, DocumentType, QuestionType } from '@innedit/innedit-type';
import { GenericData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import MessageAction from '~/components/Message/Action';
import MessageQuestion from '~/components/Message/Question';

const QuestionChildren: FC<{
  espaceId: string;
  onConfirm?: () => void;
  parentId: string;
  userId: string;
}> = function ({ espaceId, onConfirm, parentId, userId }) {
  const [questions, setQuestions] = useState<DocumentType<QuestionType>[]>();
  const [results, setResults] = useState<Record<string, string>>({});

  useEffect(() => {
    let isMounted = true;
    const model = new GenericData<QuestionType>({
      collectionName: 'questions',
    });

    model
      .find({
        orderDirection: 'desc',
        orderField: 'datetime',
        wheres: {
          parent: parentId,
        },
      })
      .then(docs => {
        if (isMounted) {
          setQuestions(docs);
        }

        return isMounted;
      })
      .catch(error => toast.error(error.message));

    return () => {
      isMounted = false;
    };
  }, [parentId]);
  const handleOnChange = (id: string, value: string) => {
    setResults(oldResults => {
      const newResults = { ...oldResults };
      newResults[id] = value;

      return newResults;
    });
  };

  const handleOnSend = async () => {
    const model = new GenericData<AnswerType>({
      espaceId,
      parentId,
      collectionName: 'answers',
      parentCollectionName: 'questions',
    });

    if (results && Object.keys(results).length > 0) {
      const answer = await model.create(
        model.initialize({
          userId,
          questionId: parentId,
        }),
      );

      const answers = Object.keys(results).map(questionId =>
        model.create(
          model.initialize({
            questionId,
            userId,
            label: results[questionId],
            parent: answer.id,
          }),
        ),
      );

      const result = await toast.promise(Promise.all(answers), {
        error: "Une erreur s'est produite lors de l'enregistrement",
        pending: "En cours d'enregistrement",
        success: 'La réponse a été enregistré avec succès',
      });

      if (result && onConfirm) {
        onConfirm();
      }
    }
  };

  return (
    <div>
      {questions && questions.length > 0 && (
        <div className="flex flex-col space-y-1.5 pt-6">
          {questions.map((question, index) => (
            <MessageQuestion
              key={question.id}
              onChange={handleOnChange}
              showLabel={questions.length > 1}
              value={question}
            />
          ))}
        </div>
      )}

      {questions && questions.length > 0 && (
        <div className="flex justify-end pt-6">
          <MessageAction label="Envoyer" onClick={handleOnSend} size="sm" />
        </div>
      )}
    </div>
  );
};

export default QuestionChildren;
