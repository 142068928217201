import styled from '@emotion/styled';
import { AnswerType, QuestionKindType } from '@innedit/innedit-type';
import { GenericData } from 'packages/innedit';
import React, { FC, ReactNode, SyntheticEvent, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { toast } from 'react-toastify';

import Button from '~/components/Button';
import IconArrowUp from '~/icons/ArrowUp';

const Textarea = styled.textarea`
  resize: none;
  outline: none;
  line-height: 24px;
  display: flex;
`.withComponent(TextareaAutosize);

const QuestionAction: FC<{
  contactId: string;
  espaceId: string;
  kind: QuestionKindType;
  onConfirm?: () => void;
  questionId: string;
  userId: string;
}> = function ({ contactId, espaceId, kind, onConfirm, questionId, userId }) {
  const [value, setValue] = useState<string>();

  const handleOnChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleOnSend = async () => {
    if (value) {
      const model = new GenericData<AnswerType>({
        espaceId,
        collectionName: 'answers',
      });

      const answer = model.initialize({
        contactId,
        questionId,
        userId,
        label: value,
      });

      const result = await toast.promise(model.create(answer), {
        error: "Une erreur s'est produite lors de l'enregistrement",
        pending: "En cours d'enregistrement",
        success: 'La réponse a été enregistré avec succès',
      });

      if (result) {
        setValue(undefined);
        if (onConfirm) {
          onConfirm();
        }
      }
    }
  };

  let Cmp: ReactNode | undefined;

  switch (kind) {
    case 'range': {
      Cmp = <div>Range</div>;
      break;
    }

    case 'text': {
      Cmp = (
        <div className="-mx-1.5 flex items-end space-x-2">
          <div className="flex flex-1 items-end rounded-[18px] border bg-light-100 pl-[12px]">
            <Textarea
              className="my-[6px] flex-1 items-center rounded-r-none bg-transparent md:my-[3px]"
              maxRows={value ? undefined : 1}
              onChange={handleOnChange}
              placeholder="Réponse"
              value={value}
            />
            <div>
              <Button
                className="m-[4px] flex h-[28px] w-[28px] rounded-full border-none md:h-[22px] md:min-h-[22px] md:w-[22px] md:min-w-[22px]"
                color="neutral"
                disabled={!value}
                iconClassName="h-[14px] w-[14px]"
                iconLeft={IconArrowUp}
                onClick={handleOnSend}
                size="xs"
                square
                title="Envoyer"
              />
            </div>
          </div>
        </div>
      );
      break;
    }

    default: {
      Cmp = <div>type non pris en charge</div>;
    }
  }

  return <div className="pt-6">{Cmp}</div>;
};

export default QuestionAction;
