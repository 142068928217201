import dayjs from 'dayjs';
import { PageProps } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

// import { useTranslation } from 'react-i18next';
import ButtonGroup from '~/components/Button/Group';
import Calendar from '~/components/Calendar';
import Chat from '~/components/Chat';
import Notebook from '~/components/Notebook';
import NotebookForm from '~/components/Notebook/Form';
import Subscription from '~/components/Subscription';
import ToggleMenu from '~/components/ToggleMenu';
import IconWindowMenu from '~/icons/WindowMenu';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const DashboardPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
}) => {
  const today = dayjs().format('YYYY-MM-DD');
  const [range, setRange] = useState<string[]>();
  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>(today);
  // const { t } = useTranslation();

  useEffect(() => {
    const date = [startDate ?? dayjs().format('YYYY-MM-DD')];
    if (endDate && startDate && startDate.localeCompare(endDate) !== 0) {
      let tmp = dayjs(startDate, 'YYYY-MM-DD').add(1, 'day');
      const end = dayjs(endDate, 'YYYY-MM-DD');
      while (tmp.diff(end, 'day') <= 0) {
        date.push(tmp.format('YYYY-MM-DD'));
        tmp = tmp.add(1, 'day');
      }
    }
    setRange(date);
  }, [endDate, startDate]);

  // const handleStartVideoRecording = () => {
  //   console.info(
  //     "navigator.userAgent.includes('iPhone'",
  //     navigator.userAgent.includes('iPhone'),
  //   );
  //   if (!navigator.userAgent.includes('iPhone')) {
  //     setHasVideo(true);
  //     setIsRecording(true);
  //     start({ audio: true, video: true });
  //   } else {
  //     const file = document.querySelector(
  //       '#input-file__image',
  //     ) as HTMLInputElement;
  //     if (file) {
  //       file.click();
  //     }
  //   }
  // };

  const handleStartDateOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setStartDate(event.currentTarget.value);
  };

  const handleEndDateOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setEndDate(event.currentTarget.value);
  };

  return (
    // const { t } = useTranslation();
    // const [inquiries, setInquiries] = useState<DocumentType<InquiryType>[]>();
    //
    // if (inquiries && inquiries.length > 0) {
    //   messages.push({
    //     notifications: inquiries.map(inquiry => ({
    //       date: inquiry.updatedAt,
    //       description: inquiry.messages[inquiry.messages.length - 1].text,
    //       icon: 'inquiries',
    //       id: inquiry.id,
    //       label: inquiry.contactName,
    //       subLabel: t('inquiry.item.nb-messages', {
    //         count: inquiry.messages.length,
    //       }),
    //       to: `/espaces/${espace.id}/inquiries/${inquiry.id}/update`,
    //     })),
    //     text: t('dashboard.inquiries.waiting.label', {
    //       count: inquiries.length,
    //     }),
    //   });
    // }
    // useEffect(() => {
    //   const inquiryData = new InquiryData({ espaceId: espace.id });
    //   const unsub = inquiryData.watch(
    //     docs => {
    //       setInquiries(docs);
    //     },
    //     {
    //       wheres: {
    //         archived: false,
    //         parent: '',
    //       },
    //     },
    //   );
    //
    //   return () => {
    //     if (unsub) {
    //       unsub();
    //     }
    //   };
    // }, [espace.id]);

    <TemplateEspace espace={espace} pathname={location.pathname} user={user}>
      <div className="mt-6 flex md:mx-6">
        <div className="flex-1">
          <ToggleMenu iconLeft={IconWindowMenu} variant="action" />
          <div className="mx-6 md:mx-0">
            <Chat user={user} />
          </div>
        </div>
        <div className="hidden md:block">
          <div className="sticky top-6">
            <Calendar
              onSelect={() => {
                console.info('selected');
              }}
            />
          </div>
        </div>
      </div>
      <div className="md:px-6">
        <div className="flex flex-col space-y-6">
          {/* <Message text="Merci, passe une belle journée." type="sender" /> */}
          {/* <div className="w-full"> */}
          {/*  {undefined !== inquiries && inquiries.length > 0 && ( */}
          {/*    <Notification */}
          {/*      description={t('dashboard.inquiries.waiting.label', { */}
          {/*        count: inquiries.length, */}
          {/*      })} */}
          {/*      icon="inquiries" */}
          {/*      label="Demandes de renseignements" */}
          {/*    /> */}
          {/*  )} */}
          {/* </div> */}

          <Subscription user={user} />

          <div className="mx-6 flex justify-center md:mx-0 md:justify-start">
            <ButtonGroup>
              <input
                className="whitespace-nowrap rounded-[15px] border bg-light-400 py-0.5 pl-3 pr-1.5 outline-none"
                max={endDate}
                onChange={handleStartDateOnChange}
                type="date"
                value={startDate}
              />
              <input
                className="whitespace-nowrap rounded-[15px] border bg-light-400 py-0.5 pl-1.5 pr-3 outline-none"
                max={today}
                min={startDate}
                onChange={handleEndDateOnChange}
                type="date"
                value={endDate}
              />
            </ButtonGroup>
          </div>
          <div className="mx-6 mt-6 md:mx-0">
            {range &&
              range.length > 0 &&
              range.map(date => (
                <Notebook
                  key={date}
                  date={date}
                  espaceId={espace.id}
                  showInput={false}
                />
              ))}
            <NotebookForm espaceId={espace.id} />
          </div>
        </div>
      </div>
    </TemplateEspace>
  );
};
export default requireEspace(DashboardPage);
