import { DocumentType, MessageType, UserType } from '@innedit/innedit-type';
import { GenericData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Message from '~/components/Message';

interface ChatProps {
  user: DocumentType<UserType>;
}
const Chat: FC<ChatProps> = function ({ user }) {
  const citation = `Il n’y a pas de réussites faciles\n ni d’échecs définitifs`;
  const [message, setMessage] = useState<DocumentType<MessageType>>();

  useEffect(() => {
    let isMounted = true;

    const messageData = new GenericData<MessageType>({
      collectionName: 'messages',
    });

    const unsub = messageData.watch(
      docs => {
        if (isMounted) {
          const [newMessage] = docs;
          setMessage(newMessage);
        }
      },
      {
        limit: 1,
        orderDirection: 'asc',
        orderField: 'createdAt',
        wheres: {
          answered: false,
          userId: user.id,
        },
      },
    );

    return () => {
      isMounted = false;
      if (unsub) {
        unsub();
      }
    };
  }, [user.id]);

  return (
    <div className="mx-auto mb-6 max-w-[400px]">
      <div id="chat-header">
        <h1 className="mb-3 text-[24px]">Bonjour {user.firstName},</h1>
        <p
          dangerouslySetInnerHTML={{ __html: citation.replace(/\n/g, '<br/>') }}
          id="citation-day"
        />
      </div>
      {message && (
        <div className="mt-12">
          <Message value={message} />
        </div>
      )}
    </div>
  );
};

export default Chat;
