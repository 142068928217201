import { QuestionOptionType } from '@innedit/innedit-type';
import React, { FC, SyntheticEvent, useState } from 'react';

const MessageQuestionRange: FC<{
  id: string;
  onChange: (id: string, value: string) => void;
  options: QuestionOptionType[];
}> = ({ id, onChange, options }) => {
  const [value, setValue] = useState<number>(0);
  const min = options ? parseInt(options[0].value, 10) : 0;
  const max = options ? parseInt(options[options.length - 1].value, 10) : 100;
  const step = options ? (max - min) / (options.length - 1) : 1;

  const handleOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.currentTarget.value, 10);
    setValue(newValue);
    onChange(id, String(newValue));
  };

  return (
    <div>
      <input
        className="w-full"
        list={`values_${id}`}
        max={max}
        min={min}
        onChange={handleOnChange}
        step={step}
        type="range"
        value={value}
      />
      <datalist id={`values_${id}`}>
        {options.map(option => (
          <option
            key={option.value}
            aria-label={option.label}
            value={option.value}
          />
        ))}
      </datalist>
    </div>
  );
};

export default MessageQuestionRange;
