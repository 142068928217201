import { DocumentType, MessageType, QuestionType } from '@innedit/innedit-type';
import { GenericData, QuestionData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Question from '~/components/Question';

const Message: FC<{ value: DocumentType<MessageType> }> = function ({
  value: { contactId, espaceId, questionId, userId, id: messageId },
}) {
  const [question, setQuestion] = useState<DocumentType<QuestionType>>();

  useEffect(() => {
    let isMounted = true;
    if (espaceId && questionId) {
      const model = new QuestionData({ espaceId });
      model
        .findById(questionId)
        .then(snapshot => {
          if (isMounted) {
            setQuestion(snapshot);
          }

          return isMounted;
        })
        .catch(error => toast.error(error.message));
    }

    return () => {
      isMounted = false;
    };
  }, [espaceId, questionId]);

  const handleOnConfirm = () => {
    const messageData = new GenericData<MessageType>({
      collectionName: 'messages',
    });

    messageData.update(messageId, {
      answered: true,
    });
  };

  return (
    <div>
      {contactId && userId && question && (
        <Question
          contactId={contactId}
          onConfirm={handleOnConfirm}
          userId={userId}
          value={question}
        />
      )}
    </div>
  );
};

export default Message;
