import styled from '@emotion/styled';
import { DocumentType, QuestionType } from '@innedit/innedit-type';
import React, { FC } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import MessageQuestionRange from '~/components/Message/Question/Range';

const Textarea = styled.textarea`
  resize: none;
  outline: none;
  line-height: 24px;
  display: flex;
`.withComponent(TextareaAutosize);

const MessageQuestion: FC<{
  onChange: (id: string, value: string) => void;
  showLabel?: boolean;
  value: DocumentType<QuestionType>;
}> = function ({ onChange, showLabel, value: question }) {
  const { kind, label, options } = question;
  let Cmp;
  switch (kind) {
    case 'range': {
      if (!options || 0 === options.length) {
        return <div className="text-danger-500">Erreur de configuration</div>;
      }

      Cmp = (
        <MessageQuestionRange
          id={question.id}
          onChange={onChange}
          options={options}
        />
      );
      break;
    }

    case 'select': {
      if (!options || 0 === options.length) {
        return <div className="text-danger-500">Erreur de configuration</div>;
      }

      Cmp = (
        <div className="flex w-full flex-wrap justify-end space-x-3">
          {options.map(option => (
            <div
              key={option.value}
              className="mb-[4px] rounded-[18px] border border-[#007AFF] px-3 py-1.5 text-[17px] leading-[22px] text-[#007AFF] md:text-[15px]"
            >
              {option.label}
            </div>
          ))}
        </div>
      );
      break;
    }

    case 'text': {
      Cmp = (
        <div className="flex flex-1 items-end rounded-[18px] border bg-light-100 pl-[12px]">
          <Textarea className="my-[6px] flex-1 items-center rounded-r-none bg-transparent md:my-[3px]" />
        </div>
      );
      break;
    }

    default:
      Cmp = (
        <div className="text-danger-500">{`Le type n'est pas prise en charge`}</div>
      );
  }

  return (
    <div>
      {showLabel && <div>{label}</div>}
      {Cmp}
    </div>
  );
};

export default MessageQuestion;
